import { Amplify } from 'aws-amplify';
const awsmobile = {
    'aws_project_region': 'us-east-1',
    'aws_cognito_region': 'us-east-1',
    'aws_user_pools_id': 'us-east-1_ytHBcWdvb',
    'aws_user_pools_web_client_id': '6rlhgdl0ppol6cbj7ub7gpj33t',
    'oauth': {},
    'endpoints': [
        {
            name: 'backend-api',
            endpoint: 'https://staging.api.daf.netafoundation.org',
            custom_header: async () => {
        try {
          const currentUser = await Amplify.currentSession();
          // If there is a user that is logged in
          if (currentUser) {
            const token = currentUser.idToken.jwtToken;
            return { Authorization: token };
          }
        } catch (error) {
          if (error === 'No current user') {
            // Get guest user (not logged in)
            const currentUser = await Amplify.currentCredentials();
            if (currentUser) {
              const token = currentUser.sessionToken;
              return { Authorization: token };
            }
          }
        }
      },
    },
  ],
    'Analytics': {
        disabled: true
    }
};

export default awsmobile;
